import React, {useEffect} from "react";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    InputAdornment,
    MenuItem,
    OutlinedInput, Select,
    Stack
} from "@mui/material";
import {useSwiper} from "swiper/react";
import axios from "axios";
import ColorButton from "../ColorButton";

export default function Gender({onComplete}) {
    const [gender, setGender] = React.useState("");
    const [genderCustom, setGenderCustom] = React.useState("");
    const [error, setError] = React.useState("")

    const doNext = () => {
        if (gender === "else" && genderCustom === "") {
            setError("請輸入性別")
            return
        }
        onComplete({
            gender: gender,
            genderCustom: genderCustom
        })
        swiper.slideNext()
        setError("")
    }

    const swiper = useSwiper();
    return <>
        <Stack alignItems={"stretch"} direction={"column"} spacing={2} p={2}>
            <FormControl fullWidth error={error !== ""}>
                <FormLabel>性別</FormLabel>
                <Select variant={"outlined"}
                        value={gender}
                        onChange={e => {
                            setGender(e.target.value)
                            setError("")
                        }}
                >
                    <MenuItem value={"male"}>男</MenuItem>
                    <MenuItem value={"female"}>女</MenuItem>
                    <MenuItem value={"else"}>其他</MenuItem>
                    <MenuItem value={"none"}>不提供</MenuItem>
                </Select>
                <FormHelperText>{gender === "else" ? "" : error}</FormHelperText>
            </FormControl>
            {gender === "else" && <FormControl fullWidth error={error !== ""}>
                <FormLabel>性別</FormLabel>
                <OutlinedInput value={genderCustom} onChange={(event) => {
                    setGenderCustom(event.target.value)
                }} onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        doNext()
                    }
                }}/>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>}
        </Stack>

        <Box pr={2} pl={2} textAlign={"right"}>
            <Button variant={"text"} sx={{float: "left"}} onClick={() => {
                swiper.slidePrev()
            }}>上一步</Button>
            <ColorButton variant={"contained"} onClick={() => {

                doNext()
            }}>下一步</ColorButton>
        </Box>
    </>
}