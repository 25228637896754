import {Box, Grid, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import Name from "../Components/Register/Name";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import Account from "../Components/Register/Account";
import Password from "../Components/Register/Password";
import Gender from "../Components/Register/Gender";
import Birthday from "../Components/Register/Birthday";
import Welcome from "../Components/Register/Welcome";
import axios from "axios";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

const field = [Name, Account, Password, Gender, Birthday];
const titles = [{t: "名字", d: "在任何訂單都可能會用到"},
    {t: "帳號", d: "帳號是你登入的識別"},
    {t: "密碼", d: "不要被別人猜到了"},
    {t: "性別", d: "你可以是一架戰鬥直升機"},
    {t: "生日", d: "大驚喜！"}];

export default function Register() {
    const location = useLocation()

    const [page, setPage] = React.useState(0);

    const register = (registerData) => {
        const form = new FormData();
        form.append("account", registerData.username);
        form.append("password", registerData.password);
        form.append("fname", registerData.fname);
        form.append("lname", registerData.lname);
        form.append("gender", registerData.gender);
        form.append("genderCustom", registerData.genderCustom);
        form.append('year', registerData.birthday.getFullYear());
        form.append('month', registerData.birthday.getMonth() + 1);
        form.append('day', registerData.birthday.getDate());

        axios.post('/api/register', form, {withCredentials: true})
            .then((response) => {

                if (response.data.status) {
                    setTimeout(() => {
                        let values = queryString.parse(location.search)
                        if (values.continue) {
                            window.location.href = values.continue
                        } else {
                            window.location.href = "https://account.zentring.net/";
                        }
                    }, 2000)
                } else {

                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
            });
    }
    useEffect(() => {

    }, []);

    const [registerData, setRegisterData] = React.useState({})

    const [registered, setRegistered] = React.useState(false)

    const swiperRef = React.useRef(null);
    return <>
        {registered ? <Welcome registerData={registerData}/> :
            <Grid container>
                <Grid item xs={6}>
                    <Stack sx={{ml: 4, height: "100%"}} direction={"column"} justifyContent={"start"}>
                        <Typography variant={"h4"}>{titles[page].t}</Typography>
                        <Typography variant={"body1"}>{titles[page].d}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Swiper noSwiping allowTouchMove={false} onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }} modules={[Navigation]} className="mySwiper" onRealIndexChange={(swiperCore) => {
                        setPage(swiperCore.realIndex);
                    }}>
                        {field.map((Comp, key) => {
                            return <SwiperSlide key={key}>
                                <Comp onComplete={(data) => {
                                    setRegisterData({...registerData, ...data})
                                }} onRegister={() => {
                                    console.log(registerData)
                                    register(registerData)
                                    setRegistered(true)
                                }}/>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </Grid>
            </Grid>
        }
    </>
}
