import React from "react";
import {Box, Button, FormControl, FormHelperText, FormLabel, OutlinedInput, Stack} from "@mui/material";
import {useSwiper} from "swiper/react";
import ColorButton from "../ColorButton";

export default function Password({onComplete, trigger}) {
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    const [error, setError] = React.useState("");

    const doNext = () => {
        if (password === "" || confirmPassword === "") {
            setError("請輸入密碼")
            return
        }
        if (password.length < 8) {
            setError("密碼長度不足 8 碼")
            return
        }
        if (password !== confirmPassword) {
            setError("密碼不一致")
            return
        }

        /*
        * ^[a-zA-Z0-9!@#$%^&*()_+{}|:<>?;',\[\]\\\.]+$
        */

        /* Only allow the following character
         * a~z A~Z 0~9 ! @ # $ % ^ & * ( ) _ + { } | : < > ? ; ' , [ ] / \ .
         * and space key
         */
        if (!/^[a-zA-Z0-9!@#$%^&*()_+{} |:<>?;',\[\]\\.]+$/.test(password)) {
            setError("密碼包含非法字元")
            return
        }

        onComplete({
            password: password,
        })
        swiper.slideNext()
    }

    const swiper = useSwiper();
    return <>
        <Stack alignItems={"stretch"} direction={"row"} spacing={2} p={2}>
            <FormControl fullWidth error={error !== ""}>
                <FormLabel>密碼</FormLabel>
                <OutlinedInput type={"password"} value={password} onChange={(event) => {
                    setPassword(event.target.value)
                    setError("")
                }}/>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>

            <FormControl fullWidth error={error !== ""}>
                <FormLabel>確認密碼</FormLabel>
                <OutlinedInput type={"password"} value={confirmPassword}
                               onChange={(event) => {
                                   setConfirmPassword(event.target.value)
                                   setError("")
                               }}
                               onKeyDown={(event) => {
                                   if (event.key === "Enter") {
                                       doNext()
                                   }
                               }}
                />
            </FormControl>
        </Stack>

        <Box pr={2} pl={2} textAlign={"right"}>
            <Button variant={"text"} sx={{float: "left"}} onClick={() => {
                swiper.slidePrev()
            }}>上一步</Button>
            <ColorButton variant={"contained"} onClick={() => {
                doNext()
            }}>下一步</ColorButton>
        </Box>
    </>
}