import React from 'react';
import "./App.css"
import SelectAccount from "./Page/SelectAccount";
import Login from "./Page/Login";
import Register from "./Page/Register";
import Recovery from "./Page/Recovery";
import queryString from "query-string";
import {Card, CardContent, Container, styled, Typography} from "@mui/material";
import withStyles from "react-jss";

import {
    Routes,
    Route, Navigate,
} from "react-router-dom";
import {withRouter} from "./withRouter";
import axios from "axios";

const classes = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    loginInput: {
        width: 80 + "%",
        margin: "auto",
    },
    link: {
        textDecoration: "none",
        color: "#512da8",
    },
    wrapper: {
        width: 100 + "%",
        margin: 0,
        position: "absolute",
        top: 50 + "%",
        left: 50 + "%",
        transform: "translate(-50%,-50%)",
    },
    action: {
        marginTop: 30,
    },
    loginTF: {
        marginTop: 20,
    },
    // container: {
    //     transition: theme.transitions.create("all", {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     })
    // }
});

class App extends React.Component {
    constructor(prop) {
        super(prop);
        this.props = prop;
        this.state = {
            currentPath: "",
            isAjaxPending: false,
            loggedInProfile: window.accounts ? window.accounts.users : {},
            activeUser: window.accounts ? window.accounts.activeUser : "",
            isBySelected: false,
            isLoaded: false,

            selectedUser: "",
            recoveryUser: "",

            currentUser: "",

        }
        if (this.state.currentPath === "") {
            this.state.currentPath = window.location.pathname.split('/')[2]
        }

        this.getLoggedInList.bind(this);
        this.getLoggedInList();
    }

    getLoggedInList() {
        const {searchParams} = this.props;
        if (window.accounts) {
            if (window.location.pathname === "/v3/" || window.location.pathname === "/v3") {
                if (Object.keys(window.accounts.users).length > 0) {
                    this.props.navigate({pathname: '/v3/select/', search: "?" + searchParams.toString()});
                } else {
                    this.props.navigate({pathname: '/v3/signin/', search: "?" + searchParams.toString()});
                }
            }
        } else {
            const form = new FormData()

            axios.post('/api/getUser?withInformation', form, {withCredentials: true})
                .then((response) => {
                    // handle success
                    // console.log(response);
                    this.setState({
                        isAjaxPending: false,
                        loggedInProfile: response.data.data.users,
                        activeUser: response.data.data.activeUser,
                        isLoaded: true
                    })
                    if (window.location.pathname === "/v3/" || window.location.pathname === "/v3") {
                        if (Object.keys(response.data.data.users).length > 0) {
                            this.props.navigate("/v3/select/?" + searchParams.toString())
                        } else {
                            this.props.navigate("/v3/signin/?" + searchParams.toString())
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                });
        }
    }

    componentDidMount() {
        let values = queryString.parse(this.props.location.search);
        if (values.user) {
            this.setState({currentUser: values.user})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;
        if (location.pathname !== prevProps.location.pathname) {
            this.handleLocationChange(location);
        }
    }

    handleLocationChange = (location) => {
        this.setState({
            currentPath: location.pathname.split('/')[2],
        })
    }

    render() {
        const {classes} = this.props;

        let accounts = Object.entries(this.state.loggedInProfile)
        if (accounts.length) {
            let first = accounts.find(item => item[0] === this.state.activeUser)
            accounts = accounts.filter((item) => item[0] !== this.state.activeUser)
            accounts.unshift(first)
        }

        return (
            <div className={classes.root} style={{height: 100 + "VH"}}>
                <Container
                    sx={{
                        transition: (theme) => theme.transitions.create('all', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        })
                    }}
                    maxWidth={this.state.currentPath === "signin" || this.state.currentPath === "select" || this.state.currentPath === "recovery" ? "xs" : "md"}
                >
                    {/*md for signup*/}
                    <Card style={{height: 80 + "vh", maxHeight: 500}}>
                        <CardContent style={{height: 100 + "%", position: "relative",}}>
                            <Typography variant="h5" component="h2">
                                Zentring Account
                            </Typography>
                            <Typography className={classes.title} color="textSecondary">
                                {this.state.currentPath === "signup" ? "註冊帳號" : ""}
                                {this.state.currentPath === "select" ? "登入以繼續" : ""}
                                {this.state.currentPath === "signin" ? "登入以繼續" : ""}
                                {this.state.currentPath === "recovery" ? "恢復賬戶存取權" : ""}
                            </Typography>
                            <div className={classes.wrapper}>
                                <Routes>
                                    <Route path="/v3/recovery" element={<Recovery currentUser={this.state.currentUser}
                                                                                  onCancelRecovery={() => {
                                                                                      const {searchParams} = this.props;
                                                                                      this.props.navigate("/v3/signin/?" + searchParams.toString())
                                                                                  }}/>}/>
                                    <Route path="/v3/signup" element={<Register/>}/>
                                    <Route path="/v3/signin" element={<Login accounts={accounts}
                                                                             onCurrentUserUpdate={(currentUser, nextState) => {
                                                                                 this.setState({currentUser: currentUser})
                                                                             }}
                                                                             currentUser={this.state.currentUser}/>}/>
                                    <Route path="/v3/select" element={<>
                                        <SelectAccount accounts={accounts} activeUser={this.state.activeUser}
                                                       onCurrentUserUpdate={(currentUser) => {
                                                           this.setState({currentUser: currentUser})
                                                           const {searchParams} = this.props;
                                                           this.props.navigate("/v3/signin/?" + searchParams.toString())
                                                       }}/>
                                        {accounts.length < 1 && this.state.isLoaded ? <Navigate to="/v3/signin"/> : ""}
                                    </>}/>
                                    <Route path="/v3/" element={<Navigate to={"/v3/select"}/>}/>
                                </Routes>
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        );
    }

}

export default withRouter(withStyles(classes)(App));
