import {Box, Typography} from "@mui/material";
import React from "react";

export default function Welcome({registerData}) {
    return <Box sx={{ml: 4}} textAlign={"center"}>
        <Typography variant={"h4"}>恭喜 {registerData?.fname}</Typography>
        <Typography variant={"body1"}>
            你現在可以使用大部分的 Zentring 服務了
        </Typography>
        <Typography variant={"body1"}>
            正在前往下一步...
        </Typography>
    </Box>
}