import React from 'react';
import {Link} from "react-router-dom";
import queryString from "query-string";
import {browserName, osName, mobileModel} from 'react-device-detect';
import {Avatar, Button, TextField, Typography} from "@mui/material";
import {withRouter} from "../withRouter";
import {deepPurple} from "@mui/material/colors";
import withStyles from "react-jss";
import axios from "axios";

const classes = theme => ({
    loginTF: {
        marginTop: 20,
    },
    loginInput: {
        width: 80 + "%",
        margin: "auto",
    },
    link: {
        textDecoration: "none",
        color: "#512da8",
    },
    action: {
        marginTop: 30,
    },
});


class Login extends React.Component {
    constructor(prop) {
        super(prop);
        this.props = prop;

        this.state = {
            page: 0,
            username: "",
            password: "",
            isAjaxPending: false,
            isUserNotFound: false,
            isPasswordWrong: false,

            profileImage: "https://account.zentring.net/none.png",
        }

        this.handleContinue.bind(this)
        this.handleLogin.bind(this)
    }

    handleContinue() {

        this.setState({isAjaxPending: true})
        let account = this.props.currentUser
        if (account === "") {
            account = this.state.username
        }
        axios.get('/api/isUserExist?account=' + account)
            .then((response) => {
                if (response.data.status) {
                    this.setState({
                        name: response.data.data.fname,
                        page: 1,
                        profileImage: response.data.data.image
                    })
                    if(this.props.currentUser !== account){
                        this.props.onCurrentUserUpdate(account, {
                            name: response.data.data.fname,
                            page: 1,
                            profileImage: response.data.data.image
                        });
                    }
                } else {
                    this.setState({
                        isUserNotFound: true
                    })
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(() => {
                this.setState({
                    isAjaxPending: false
                })
            })
    }

    handleLogin() {
        this.setState({isAjaxPending: true})

        const form = new FormData();

        form.append('account', this.props.currentUser);
        form.append('password', this.state.password);
        form.append('browserName', this.state.browserName);
        form.append('platform', this.state.platform);

        axios.post('/api/login', form, {withCredentials: true})
            .then((response) => {
                if (response.data.status) {
                    let values = queryString.parse(this.props.location.search);
                    if (values.continue) {
                        window.location.href = values.continue
                    } else {
                        window.location.href = "https://account.zentring.net/";
                    }
                } else {
                    this.setState({
                        isPasswordWrong: true
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({
                    isAjaxPending: false
                })
            });
    }

    componentDidMount() {
        if (this.props.currentUser && this.state.page === 0) {
            this.handleContinue()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currentUser !== prevProps.currentUser && this.props.currentUser !== "") {
            this.handleContinue()
        }
    }

    render() {
        const {classes} = this.props;

        const ColorButton = withStyles((theme) => ({
            root: {
                color: "white",
                // color: theme.palette.getContrastText(deepPurple[500]),
                backgroundColor: deepPurple[500],
                '&:hover': {
                    backgroundColor: deepPurple[700],
                },
            },
        }))(Button);

        return (
            <>
                {this.props.currentUser === "" ? (
                    <form noValidate autoComplete="new-password" className={classes.loginInput}
                          onSubmit={e => {
                              e.preventDefault();
                              this.handleContinue();
                          }}>
                        <TextField variant={"standard"} className={classes.loginTF} id="login-username"
                                   autoComplete="new-password"
                                   helperText={this.state.isUserNotFound ? "找不到已註冊的 Zentring 帳戶" : ""}
                                   error={this.state.isUserNotFound}
                                   value={this.state.username}
                                   label="輸入帳號、電子郵件或手機號碼" fullWidth
                                   onChange={(event) => {
                                       this.setState({
                                           username: event.target.value,
                                           isUserNotFound: false,
                                       })
                                   }}/>
                        <div className={classes.action}>
                            <ColorButton variant="contained" color="primary"
                                         className={classes.margin}
                                         style={{float: "right",}}
                                         onClick={() => {
                                             this.setState({isBySelected: false,})
                                             this.handleContinue()
                                         }}
                                         disabled={this.state.isAjaxPending}
                            >
                                下一步
                            </ColorButton>
                            <Link className={classes.link} to={{
                                pathname: '/v3/signup',
                                search: this.props.location.search
                            }}>建立帳戶</Link>
                            {this.props.accounts.length < 1 ? "" :
                                <span> 或 <Link
                                    className={classes.link} to={{
                                    pathname: '/v3/select',
                                    search: this.props.location.search
                                }}>選擇其他帳戶</Link></span>}

                        </div>
                    </form>
                ) : (
                    <form noValidate autoComplete="off" className={classes.loginInput}
                          onSubmit={e => {
                              e.preventDefault();
                              this.handleLogin();
                          }}>
                        <TextField variant={"standard"} label="輸入帳號、電子郵件或手機號碼" style={{display: "none"}}
                                   value={this.state.username}/>
                        <Avatar alt="Profile image" src={this.state.profileImage}
                                style={{display: "inline-block", verticalAlign: "middle",}}/>
                        <Typography variant="h5" component="h2"
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        lineHeight: 40 + "px",
                                        marginLeft: 10
                                    }}>
                            歡迎，{this.state.name}
                        </Typography>
                        <TextField variant={"standard"} className={classes.loginTF} id="login-password"
                                   label="輸入密碼"
                                   value={this.state.password}
                                   type={"password"}
                                   helperText={this.state.isPasswordWrong ? "密碼不正確" : ""}
                                   error={this.state.isPasswordWrong}
                                   onChange={event => {
                                       this.setState({
                                           password: event.target.value,
                                           isPasswordWrong: false
                                       })
                                   }}
                                   onKeyDown={(e) => {
                                       if (e.key === "Enter") {
                                           this.handleLogin();
                                       }
                                   }}
                                   fullWidth/>
                        <div className={classes.action}>
                            <ColorButton variant="contained" color="primary"
                                         className={classes.margin}
                                         style={{float: "right",}}
                                         onClick={() => {
                                             this.handleLogin();
                                         }}
                                         disabled={this.state.isAjaxPending}
                            >
                                登入
                            </ColorButton>
                            {
                                queryString.parse(this.props.location.search).user ? <></> :
                                    <Link className={classes.link} to={{
                                        pathname: this.state.isBySelected ? '/v3/select' : '/v3/signin',
                                        search: this.props.location.search
                                    }} onClick={() => {
                                        this.setState({page: 0})
                                        this.props.onCurrentUserUpdate("");
                                    }}>不是 {this.state.name} 嗎</Link>
                            }

                            <br/>
                            <Link className={classes.link} to={{
                                pathname: '/v3/recovery',
                                search: this.props.location.search
                            }} onClick={() => {
                                this.setState({page: 0})
                            }}>忘記密碼</Link>
                        </div>
                    </form>
                )}
            </>
        );
    }

}

export default withRouter(withStyles(classes)(Login));
