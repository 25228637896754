import React from 'react';
import {Link} from "react-router-dom";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography
} from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import BlockIcon from '@mui/icons-material/Block';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {withRouter} from "../withRouter";
import withStyles from "react-jss";
import axios from "axios";

const classes = theme => ({
    loginTF: {
        marginTop: 20,
    },
    loginInput: {
        width: 80 + "%",
        margin: "auto",
    },
    link: {
        textDecoration: "none",
        color: "#512da8",
    },
    action: {
        marginTop: 30,
    },
});

class Recovery extends React.Component {
    constructor(prop) {
        super(prop);
        this.props = prop;

        this.state = {
            recovery: []
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentDidMount() {
        if (!this.props.currentUser) {
            this.props.onCancelRecovery()
        } else {

            axios.get('/api/listRecovery?username=' + this.props.currentUser, {withCredentials: true})
                .then((response) => {
                    if (response.data.status) {
                        this.setState({recovery: response.data.data})
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                });
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <>
                <List>
                    {this.state.recovery.map((v, k) => {
                        return <ListItemButton onClick={() => {
                            alert("Not implement yet")
                        }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <MailIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        使用電子郵件救援
                                    </Typography>
                                </React.Fragment>}
                                secondary={v.target}/>
                        </ListItemButton>
                    })}
                    {this.state.recovery.length === 0 ?
                        <>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <BlockIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body1"
                                            color="textPrimary"
                                        >
                                            您沒有任何自助帳戶救援方法
                                        </Typography>
                                    </React.Fragment>}
                                    secondary={"就叫你完善帳戶資訊了"}/>
                            </ListItem>
                        </> : <>

                        </>}
                    <ListItemButton component={Link} to={{
                        pathname: '/v3/signin',
                        search: this.props.location.search
                    }} onClick={() => {
                        this.props.onCancelRecovery()
                    }}>
                        <ListItemAvatar>
                            <Avatar>
                                <KeyboardBackspaceIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={"我想起密碼了"}/>
                    </ListItemButton>
                </List>
            </>
        );
    }

}

export default withRouter(withStyles(classes)(Recovery));
