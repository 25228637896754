import React from 'react';
import {Link} from "react-router-dom";
import queryString from "query-string";
import {Avatar, List, ListItemAvatar, ListItemButton, ListItemText, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {withRouter} from "../withRouter";
import withStyles from "react-jss";
import axios from "axios";

const classes = theme => ({
    root: {
        marginTop: 40,
    },
    description: {
        fontSize: 14,
    },
    link: {
        textDecoration: "none",
        color: "unset",
    },
    back: {
        color: "black",
        "& :active": {
            color: "black",
        },
        "& :hover": {},

    },
});


class SelectAccount extends React.Component {
    constructor(prop) {
        super(prop);
        this.props = prop;

        this.state = {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <List component={"div"} style={{overflowY: "auto", maxHeight: "380px", position: "relative", bottom: 0}}>
                {this.props.accounts.map((item, key) => {
                    // console.log(item)
                    return <ListItemButton key={key}
                                           onClick={() => {
                                               if (item[0] === this.props.activeUser && item[1].isLogin) {
                                                   let values = queryString.parse(this.props.location.search);
                                                   if (values.continue) {
                                                       window.location.href = values.continue
                                                   } else {
                                                       window.location.href = "https://account.zentring.net/";
                                                   }
                                                   return;
                                               }
                                               if (item[1].isLogin) {
                                                   axios.get('/api/activeUser?username=' + item[0], {withCredentials: true})
                                                       .then((response) => {
                                                           let values = queryString.parse(this.props.location.search);
                                                           if (values.continue) {
                                                               window.location.href = values.continue
                                                           } else {
                                                               window.location.href = "https://account.zentring.net/";
                                                           }
                                                       })
                                                       .catch(function (error) {
                                                           console.log(error);
                                                       })
                                                       .finally(function () {
                                                       });
                                               } else {
                                                   this.setState({
                                                       username: item[0],
                                                       page: 2,
                                                       isBySelected: true
                                                   }, () => {
                                                       this.props.onCurrentUserUpdate(item[0]);

                                                   })
                                               }
                                           }}>
                        <ListItemAvatar>
                            <Avatar alt={item[1].displayName}
                                    src={item[1].image}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<>
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {item[1].displayName}
                                </Typography>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {(item[0] === this.props.activeUser && item[1].isLogin) ? " (目前登入中)" : ""}
                                    {item[1].isLogin ? "" : " (未登入)"}
                                </Typography>
                            </>}
                            secondary={item[0] + "@zentring.net"}/>
                    </ListItemButton>;
                })}
                <ListItemButton component={Link} to={{
                    pathname: '/v3/signin',
                    search: this.props.location.search
                }}>
                    <ListItemAvatar>
                        <Avatar>
                            <AddIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={"登入其他帳號"}/>
                </ListItemButton>
            </List>
        );
    }

}

export default withRouter(withStyles(classes)(SelectAccount));
