import React, {useEffect} from "react";
import {Box, Button, FormControl, FormHelperText, FormLabel, InputAdornment, OutlinedInput, Stack} from "@mui/material";
import {useSwiper} from "swiper/react";
import axios from "axios";
import ColorButton from "../ColorButton";

export default function Account({onComplete}) {
    const [username, setUsername] = React.useState("");
    const [error, setError] = React.useState("")

    const doNext = () => {
        if (username === "") {
            setError("請輸入帳號")
            return
        }

        if (/[^0-9a-zA-Z]/.test(username)) {
            setError("帳號只能使用數字和字母")
            return
        }

        if (/^[0-9]/.test(username)) {
            setError("帳號不能以數字開頭")
            return
        }

        axios.get("/api/isUserExist?account=" + username).then(r => {
            if (r.data.status) {
                setError("帳號已存在")
            } else {
                onComplete({
                    username: username,
                })
                swiper.slideNext()
                setError("")
            }
        })
    }

    const swiper = useSwiper();
    return <>
        <Stack alignItems={"stretch"} direction={"row"} spacing={2} p={2}>
            <FormControl fullWidth error={error !== ""}>
                <FormLabel>帳號</FormLabel>
                <OutlinedInput value={username} onChange={(event) => {
                    setUsername(event.target.value.toLowerCase())
                }} endAdornment={<InputAdornment
                    position="end">@zhost.net</InputAdornment>} onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        doNext()
                    }
                }}/>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        </Stack>

        <Box pr={2} pl={2} textAlign={"right"}>
            <Button variant={"text"} sx={{float: "left"}} onClick={() => {
                swiper.slidePrev()
            }}>上一步</Button>
            <ColorButton variant={"contained"} onClick={() => {
                doNext()
            }}>下一步</ColorButton>
        </Box>
    </>
}