import React from "react";
import {Box, Button, FormControl, FormHelperText, FormLabel, Stack} from "@mui/material";
import {useSwiper} from "swiper/react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {zhTW} from "date-fns/locale";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import ColorButton from "../ColorButton";

export default function Birthday({onComplete, onRegister}) {
    const [birthday, setBirthday] = React.useState(null);

    const [error, setError] = React.useState("")

    const swiper = useSwiper();
    return <>
        <Stack alignItems={"stretch"} direction={"row"} spacing={2} p={2}>
            <FormControl fullWidth error={error !== ""}>
                <LocalizationProvider dateAdapter={AdapterDateFns}
                                      adapterLocale={zhTW}>
                    {/*utils={DateFnsUtils} locale={zhTWLocale}*/}
                    <DatePicker variant={"outlined"}
                                autoOk
                                format="MM/dd/yyyy"
                                openTo="day"
                                label="出生日期"
                                value={birthday}
                                maxDate={new Date()}
                                onChange={date => {
                                    setBirthday(date)
                                    setError("")

                                    //Last step, send it directly
                                    onComplete({
                                        birthday: date,
                                    })
                                }}
                                slotProps={{
                                    textField: {
                                        variant: "outlined"
                                    }
                                }}
                                orientation={"landscape"}
                                error={error !== ""}
                                helperText={error}
                    />
                </LocalizationProvider>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        </Stack>

        <Box pr={2} pl={2} textAlign={"right"}>
            <Button variant={"text"} sx={{float: "left"}} onClick={() => {
                swiper.slidePrev()
            }}>上一步</Button>
            <ColorButton variant={"contained"} onClick={() => {
                if (birthday === null) {
                    setError("請輸入出生日期")
                    return
                }
                onComplete({
                    birthday: birthday,
                })
                onRegister()

            }}>註冊</ColorButton>
        </Box>
    </>
}