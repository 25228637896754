import withStyles from "react-jss";
import {deepPurple} from "@mui/material/colors";
import {Button} from "@mui/material";

export default withStyles((theme) => ({
        root: {
            color: "white",
            backgroundColor: deepPurple[500],
            '&:hover': {
                backgroundColor: deepPurple[700],
            },
        },
    }))(Button);
