import React from "react";
import {Box, FormControl, FormHelperText, FormLabel, OutlinedInput, Stack} from "@mui/material";
import {useSwiper} from "swiper/react";
import {Link, useLocation} from "react-router-dom";
import ColorButton from "../ColorButton";


export default function Name({onComplete, trigger}) {
    const [fname, setFName] = React.useState("");
    const [lname, setLName] = React.useState("");

    const [error, setError] = React.useState("");

    const swiper = useSwiper();

    const location = useLocation()

    const doNext = () => {
        if (fname === "" || lname === "") {
            setError("請輸入姓名")
            return
        }
        onComplete({
            fname: fname,
            lname: lname
        })
        swiper.slideNext()
    }
    return <>
        <Stack alignItems={"stretch"} direction={"row"} spacing={2} p={2}>
            <FormControl fullWidth error={error !== ""}>
                <FormLabel>姓</FormLabel>
                <OutlinedInput value={lname} onChange={(event) => {
                    setLName(event.target.value)
                    setError("")
                }}/>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>

            <FormControl fullWidth error={error !== ""}>
                <FormLabel>名</FormLabel>
                <OutlinedInput value={fname} onChange={(event) => {
                    setFName(event.target.value)
                    setError("")
                }} onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        doNext()
                    }
                }}/>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        </Stack>

        <Box pr={2} pl={2} textAlign={"right"}>
            <Link style={{
                float: "left",
                textDecoration: "none",
                color: "#512da8",
            }} to={{
                pathname: '/v3/signin',
                search: location.search
            }}>登入</Link>
            <ColorButton variant={"contained"} onClick={() => {
                doNext()
            }}>下一步</ColorButton>
        </Box>
    </>
}