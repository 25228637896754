import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";

export function withRouter(Children) {
    return (props) => {

        let [searchParams, setSearchParams] = useSearchParams();
        const match = {params: useParams()};
        const navigate = useNavigate()
        const location = useLocation()
        return <Children {...props} match={match} navigate={navigate} location={location} searchParams={searchParams} setSearchParams={setSearchParams}/>
    }
}